import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import Content, { SMALL } from '../components/content';
import {
  View,
  Text,
  Title,
  Subtitle,
  Subsubtitle,
  P,
  Screenshot,
  Link
} from '../components/common';
import { colors } from '../style';
import { format, addMinutes, subMinutes } from 'date-fns';

export function toUTC(date) {
  const offset = date.getTimezoneOffset();
  return Math.sign(offset) !== -1
    ? addMinutes(date, offset)
    : subMinutes(date, Math.abs(offset));
}

export default ({ data }) => {
  return (
    <Layout>
      <Content
        style={{
          width: 600,
          [SMALL]: { width: 'auto', padding: 15 },

          '& ul': {
            listStyleType: 'none'
          },
          '& ul ul': {
            listStyleType: 'disc'
          },
          '& strong': {
            fontWeight: 600
          },
          '& code': {
            fontSize: '.95em',
            backgroundColor: 'transparent'
          },
          '& video, & img': {
            maxWidth: '100%'
          },
          '& h4': {
            marginTop: '2rem',
            fontSize: '1.15rem'
          },
          '& p': {
            margin: '1em 0'
          },

          '& .fix-badge': {
            textTransform: 'uppercase',
            padding: '2px 6px',
            borderRadius: 4,
            fontSize: 14
          },

          '& .fix-bug': {
            backgroundColor: colors.y9,
            color: colors.y1
          },

          '& .fix-feature': {
            backgroundColor: colors.b9,
            color: colors.b1
          },

          '& .fix-tweak': {
            backgroundColor: colors.n9,
            color: colors.b1
          },

          '& .fix-mobile, & .fix-desktop, & .fix-web, & .fix-api': {
            backgroundColor: colors.g9,
            color: colors.g1
          },

          '& p:first-child': { marginTop: '1.5em' },
          '& img': { marginBottom: '1.5em' }
        }}
      >
        <Title title="Release Notes" />

        <div className="font-bold mt-20 bg-red-100 text-red-900 p-8 rounded">
          These are archived release notes. All release notes for versions after
          0.0.145 can be found{' '}
          <a href="https://actualbudget.com/blog/release">here</a>
        </div>

        {data.allGithubRelease.edges.map(edge => {
          let { name, published_at } = edge.node;

          published_at = format(+toUTC(new Date(published_at)), 'MMM D, YYYY');

          return (
            <React.Fragment key={edge.node.id}>
              <Text
                id={name}
                className="text-2xl font-semibold text-blue-600 mt-24"
              >
                {name}
              </Text>
              <Text className="text-gray-500 text-sm">{published_at}</Text>
              <View dangerouslySetInnerHTML={{ __html: edge.node.body }} />
            </React.Fragment>
          );
        })}
      </Content>
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    allGithubRelease(filter: { draft: { eq: false } }) {
      edges {
        node {
          id
          name
          body
          published_at
          assets {
            browser_download_url
          }
        }
      }
    }
  }
`;
